body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.list-item-meta-avatar {
  align-self: center;
  background-color: #60b7a3;
}

.list-item-meta-title {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  line-height: 1;
}

.list-item-meta-description {
  font-size: 11px;
  font-weight: 400;
  color: #fff;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.card-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 4px;
  margin-right: 12px;
}

.list-modules-item {
  border-radius: 10px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

.list-modules-item.selected {
  border-color: #60b7a3;
  background-color: teal;
}

.list-modules-item.selected .ant-list-item-meta-title,
.list-modules-item.selected .ant-list-item-meta-description {
  color: #fff !important;
}

.list-modules-item:hover {
  border-color: #60b7a3;
  cursor: pointer;
  transform: scale(1.05);
  z-index: 1000;
}